import { LegacyQuoteRequestRoutes, QuoteRequestRoutes } from 'config/pathnames';
import { emitter, useExperiment as useExperimentOrginal } from '@marvelapp/react-ab-test';
import { experimentStore } from 'stores/store';

export const disableABTesting = true; // Toggle this to enable/disable A/B testing

class Session {
  getPlayStorageKey = (name) => `PUSHTELL-${name}-latest-play`;

  set = (name, id) => sessionStorage.setItem(this.getPlayStorageKey(name), id);

  get = (name) => sessionStorage.getItem(this.getPlayStorageKey(name));

  remove = (name) => sessionStorage.removeItem(this.getPlayStorageKey(name));
}

const experiments = {
  LSPF_VS_MSF: {
    name: 'legacy-single-page-form-vs-multi-step-form',
    variants: (queryString = null) => ({
      a: `${QuoteRequestRoutes.PEOPLE}${queryString}`,
      b: `${LegacyQuoteRequestRoutes.FORM}${queryString}`,
    }),
  },
};

const emitterInstance = (() => {
  if (disableABTesting) {
    return null;
  }

  const session = new Session();

  emitter.addPlayListener((experimentName, variantName) => {
    if (experimentName === experiments.LSPF_VS_MSF.name && variantName === 'b') {
      const message = {
        overrideStyles: {
          maxHeight: 'calc(100vh - 2rem)',
          maxWidth: '30vw',
          borderRadius: 0,
          padding: 0,
        },
      };

      window.parent?.postMessage(JSON.stringify(message), '*');
    }

    // If current session already has a play id, keep that one :-)
    if (session.get(experimentName)) {
      return;
    }

    // Otherwise start a new play
    experimentStore
      .getState()
      .play({ name: experimentName, variant: variantName })
      .then((res) => session.set(experimentName, res?.data?.id));
  });

  // Remove play after win
  emitter.addWinListener((experimentName) => session.remove(experimentName));

  return emitter;
})();

const useExperimentDisabled = () => ({ selectVariant: (variants) => variants.a });

const useExperiment = disableABTesting ? useExperimentDisabled : useExperimentOrginal;

export { emitterInstance as emitter, experiments, useExperiment };
