import classNames from 'classnames';
import styles from './Container.module.css';

interface Props {
  className?: string;
  children?: JSX.Element | JSX.Element[];
  isSummaryPage?: boolean;
}

const Container: React.FC<Props> = ({ children, className = '', isSummaryPage = false }) => (
  <div className={classNames(className, styles.Container)}>
    <div
      className={classNames(styles.Content, {
        [styles.FullHeightContent]: !isSummaryPage,
        [styles.SummaryContent]: isSummaryPage,
      })}
    >
      {children}
    </div>
  </div>
);

export default Container;
