import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as GoogleAnalytics from 'config/ga';
import {
  CheckoutRoutes,
  MoreInformationRoutes,
  QuoteRequestRoutes,
  LegacyQuoteRequestRoutes,
} from 'config/pathnames';
import { useQueryParam, StringParam } from 'use-query-params';
import { useExperiment, emitter, experiments, disableABTesting } from 'config/ab';
import RedirectToTrip from 'pages/Redirect';

require('dayjs/locale/nl');
require('dayjs/locale/en');

GoogleAnalytics.initialize();

// Checkout
const Trip: any = lazy(() => import('./pages/Checkout/Trip'));
const Thanks: any = lazy(() => import('./pages/Checkout/Thanks'));

// More information
const MoreInformationForm: any = lazy(() => import('./pages/MoreInformation/Form'));
const MoreInformationSummary: any = lazy(() => import('./pages/MoreInformation/Summary'));

// Quote request
const Contact: any = lazy(() => import('./pages/QuoteRequest/Contact'));
const Drinks: any = lazy(() => import('./pages/QuoteRequest/Drinks'));
const Extras: any = lazy(() => import('./pages/QuoteRequest/Extras'));
const People: any = lazy(() => import('./pages/QuoteRequest/People'));
const Time: any = lazy(() => import('./pages/QuoteRequest/Time'));
const Summary: any = lazy(() => import('./pages/QuoteRequest/Summary'));

// Legacy quote request
const LegacyQuoteRequestForm: any = lazy(() => import('./pages/LegacyQuoteRequest/Form'));
const LegacyQuoteRequestSummary: any = lazy(() => import('./pages/LegacyQuoteRequest/Summary'));

export default () => {
  const [type]: any = useQueryParam('type', StringParam);

  if (!disableABTesting) {
    emitter.defineVariants(
      experiments.LSPF_VS_MSF.name,
      Object.keys(experiments.LSPF_VS_MSF.variants())
    );
  }

  const { selectVariant } = useExperiment(experiments.LSPF_VS_MSF.name);

  function getInitialRoute() {
    const queryString = window.location.search;

    if (type === 'info') {
      return `${MoreInformationRoutes.FORM}${queryString}`;
    }

    return disableABTesting
      ? `${QuoteRequestRoutes.PEOPLE}${queryString}`
      : selectVariant(experiments.LSPF_VS_MSF.variants(queryString));
  }

  return (
    <Suspense fallback="">
      <Routes>
        // Checkout
        <Route path={CheckoutRoutes.TRIP} element={<Trip />} />
        <Route path={CheckoutRoutes.THANKS} element={<Thanks />} />
        <Route path={CheckoutRoutes.REMARKS_RECEIVED} element={<Thanks isRemarks />} />
        <Route path={CheckoutRoutes.INVOICE} element={<RedirectToTrip redirectFrom="invoice" />} />
        <Route path={CheckoutRoutes.PAYMENT} element={<RedirectToTrip redirectFrom="payment" />} />
        // More information
        <Route path={MoreInformationRoutes.FORM} element={<MoreInformationForm />} />
        <Route path={MoreInformationRoutes.SUMMARY} element={<MoreInformationSummary />} />
        // Legacy quote request
        <Route path={LegacyQuoteRequestRoutes.FORM} element={<LegacyQuoteRequestForm />} />
        <Route path={LegacyQuoteRequestRoutes.SUMMARY} element={<LegacyQuoteRequestSummary />} />
        // Quote request
        <Route
          path={QuoteRequestRoutes.PEOPLE}
          element={
            <People
              progress={() => ({
                currentStep: 1,
                next: QuoteRequestRoutes.TIME,
              })}
            />
          }
        />
        <Route
          path={QuoteRequestRoutes.TIME}
          element={
            <Time
              progress={() => ({
                currentStep: 2,
                next: QuoteRequestRoutes.DRINKS,
                previous: QuoteRequestRoutes.PEOPLE,
              })}
            />
          }
        />
        <Route
          path={QuoteRequestRoutes.DRINKS}
          element={
            <Drinks
              progress={() => ({
                currentStep: 3,
                next: QuoteRequestRoutes.EXTRAS,
                previous: QuoteRequestRoutes.TIME,
              })}
            />
          }
        />
        <Route
          path={QuoteRequestRoutes.EXTRAS}
          element={
            <Extras
              progress={() => ({
                currentStep: 4,
                next: QuoteRequestRoutes.CONTACT,
                previous: QuoteRequestRoutes.DRINKS,
              })}
            />
          }
        />
        <Route
          path={QuoteRequestRoutes.CONTACT}
          element={
            <Contact
              progress={() => ({
                currentStep: 5,
                next: QuoteRequestRoutes.SUMMARY,
                previous: QuoteRequestRoutes.TIME,
                shouldSubmit: true,
              })}
              showDisclaimer
            />
          }
        />
        <Route path={QuoteRequestRoutes.SUMMARY} element={<Summary showDisclaimer isSubmitted />} />
        // Get initial route
        <Route path="*" element={<Navigate to={getInitialRoute()} replace />} />
      </Routes>
    </Suspense>
  );
};
