import { initReactI18next } from 'react-i18next';
import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLanguages = ['en', 'nl'];
export const namespaces = [
  'common',
  'contact',
  'dates',
  'drinks',
  'extras',
  'group',
  'inputs',
  'invoice',
  'legacySinglePageForm',
  'moreInformation',
  'navigation',
  'price',
  'summary',
  'time',
  'thanks',
  'payment',
];

const getResources = ({
  namespaces,
  supportedLanguages,
}: {
  namespaces: Array<string>;
  supportedLanguages: Array<string>;
}) => {
  const locales: any = {};

  namespaces.forEach((namespace: string) => {
    supportedLanguages.forEach((language: string) => {
      import(`config/i18n/${language}/${namespace}.json`).then((translation) => {
        locales[language] = {
          ...locales[language],
          [namespace]: translation.default,
        };
      });
    });
  });

  return locales;
};

export const defaultNS = 'common';
export const resources = getResources({ namespaces, supportedLanguages });

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'nl',
  ns: namespaces,
  defaultNS,
  resources,
});
