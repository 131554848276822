export const CheckoutRoutes = {
  TRIP: `trip/:id`,
  PAYMENT: `payment/:id`,
  THANKS: `thanks/:id`,
  REMARKS_RECEIVED: `remarks-received/:id`,
  INVOICE: `invoice/:id`,
};

export const LegacyQuoteRequestRoutes = {
  FORM: '/legacy-quote-request/form',
  SUMMARY: '/legacy-quote-request/summary',
};

export const MoreInformationRoutes = {
  FORM: '/more-information/form',
  SUMMARY: '/more-information/summary',
};

export const QuoteRequestRoutes = {
  CONTACT: '/contact',
  DRINKS: '/drinks',
  EXTRAS: '/extras',
  PEOPLE: '/people',
  TIME: '/time',
  SUMMARY: '/summary',
};

export default {
  CheckoutRoutes,
  LegacyQuoteRequestRoutes,
  MoreInformationRoutes,
  QuoteRequestRoutes,
};
