import ReactGA from 'react-ga4'
import history from 'config/history'

export type GaEvent = {
  category: string
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
  transport?: 'beacon' | 'xhr' | 'image'
}

export const initialize = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)

  // On page hit
  ReactGA.send({
    hitType: 'pageview',
    page: history.location.pathname + history.location.search,
  })

  // On route change
  history.listen(({ location }) => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  })
}

export const event = (event: GaEvent) => ReactGA.event(event)
