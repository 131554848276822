import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

export const BugsnagInstance = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
});

export const BugsnagPerformanceInstance = BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
});

export const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
