import Pusher from 'pusher-js';

type Listeners = {
  [key: string]: (...args: any[]) => void;
};

type WebsocketBuddyArguments = {
  channel: string | null;
  driver: 'Pusher' | null;
  listeners: Listeners;
};

const PusherInstance = (channelId: string, listeners: Listeners) => {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  });

  const channel = pusher.subscribe(channelId);

  Object.keys(listeners).forEach((listener) => {
    const callback = listeners[listener];

    if ('function' !== typeof callback) {
      throw new Error(`Listener callback must be a function, but got ${typeof callback}`);
    }

    channel.bind(listener, callback);
  });

  return { pusher, channel };
};

export const WebsocketBuddy = ({
  channel = null,
  driver = null,
  listeners = {},
}: WebsocketBuddyArguments) => {
  if (!channel) {
    throw new Error(`Channel is required`);
  }

  if (!driver) {
    throw new Error(`Driver is required`);
  }

  switch (driver) {
    case 'Pusher':
      return PusherInstance(channel, listeners);
    default:
      throw new Error(`Driver ${driver} not supported`);
  }
};
