import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from 'stores/store';
import axios from 'axios';
import Spinner from 'components/Spinner';

const RedirectToTrip = ({ redirectFrom }: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const handleRedirect = useCallback(async () => {
    if (redirectFrom === 'invoice') {
      const response = await axios.get(`${apiBaseUrl}/invoice/${params.id}`);
      navigate(`/trip/${response?.data?.booking?.id}`);
    } else if (redirectFrom === 'payment') {
      const response = await axios.get(`${apiBaseUrl}/quote/${params.id}`);
      navigate(`/trip/${response?.data?.booking?.id}`);
    }
  }, [navigate, params.id, redirectFrom]);

  useEffect(() => {
    if (params.id) {
      handleRedirect();
    }
  }, [params.id, handleRedirect]);

  return <Spinner />;
};

export default RedirectToTrip;
