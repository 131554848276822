import { Container } from 'components/Container';
import { ImSpinner8 } from 'react-icons/im';
import styles from './Spinner.module.css';

export default () => (
  <Container>
    <div className={styles.Spinner}>
      <span className={styles.SpinnerContainer}>
        <ImSpinner8 className={styles.Icon} />
      </span>
    </div>
  </Container>
);
