import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import 'i18n';
import { BugsnagErrorBoundary } from 'config/bugsnag';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BugsnagErrorBoundary>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <form>
          <App />
        </form>
      </QueryParamProvider>
    </BrowserRouter>
  </BugsnagErrorBoundary>
);
